<template>
  <router-view />
</template>

<style lang="scss">
/*多选框样式*/
.van-checkbox {
  .van-checkbox__icon--checked .van-icon {
    background: $c_main;
    border-color: $c_main;
  }
}

.van-nav-bar {
  background: $c_main !important;

  .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: $c_white !important;
  }
}
.van-pull-refresh {
  min-height: 100%;
}
.addbtn {
  position: absolute;
  right: 10px;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  color: $c_white;
  font-size: 30px;
  border-radius: 50%;
  background: #0a3f70;
  box-shadow: 0 0 5px 0 #0a3f70;
  z-index: 99;
}

.custom-image {
  .van-empty__image {
    width: 146px;
    height: 110px;
  }
}
</style>

<style lang="scss" scoped>
:deep(.van-radio__icon--checked) .van-icon {
  background: $c_main;
  border-color: $c_main;
}
</style>
