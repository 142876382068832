import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface IUserInfo {
  id: string;
  username: string;
  password: string;
  type: number;
  currentEnter: number;
  isReadme: boolean;
}

export interface IUserState {
  userInfo: IUserInfo;
  token: string;
}

@Module({ namespaced: true })
export class User extends VuexModule implements IUserState {
  public token = "";
  public userInfo: IUserInfo = {
    id: "",
    username: "",
    password: "",
    type: 0,
    currentEnter: 0,
    isReadme: false,
  };

  @Mutation
  private SET_USERINFO(userInfo: IUserInfo) {
    this.userInfo = userInfo;
  }
  @Mutation
  private SET_README(flag: boolean) {
    this.userInfo.isReadme = flag;
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_CURRENT_ENTER(ployload: number) {
    this.userInfo.currentEnter = ployload;
  }

  @Mutation
  private RESET_USERINFO() {
    const password = this.userInfo.password;
    const username = this.userInfo.username;
    if (this.userInfo.isReadme) {
      this.userInfo = {
        id: "",
        username: username,
        password: password,
        type: 0,
        currentEnter: 0,
        isReadme: true,
      };
    } else {
      this.userInfo = {
        id: "",
        username: "",
        password: "",
        type: 0,
        currentEnter: 0,
        isReadme: false,
      };
    }
  }

  @Action
  public authFail() {
    this.context.commit("RESET_USERINFO");
  }
}
