import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import * as project from "@/api/projectinfo";

export interface ProjectState {
  projectinfo: any;
  projectList: any;
  projectinfoDetail: any;
  active: any;
  perms: any;
  qualitysafe: any;
  sjjdetail: any;
  qzjdetail: any;
  xlptdetail: any;
  lbfhdetail: any;
}
@Module({ namespaced: true })
export class Project extends VuexModule implements ProjectState {
  projectinfo = null; //当前选择的项目
  projectinfoDetail = null; //地图项目详情所需要的id
  projectList = {};
  active = 0;
  perms = [];
  qualitysafe = {};
  sjjdetail = {};
  qzjdetail = {};
  lbfhdetail = {};
  xlptdetail = {};

  @Mutation
  private SET_PROJECTLIST(ployload: any) {
    this.projectList = ployload;
  }
  @Mutation
  private async SET_SELECTPROJECT(ployload: any) {
    this.projectinfo = ployload;
    await project.saveDefaultProjectId(ployload.projectId);
  }
  @Mutation
  private SET_projectinfoDetail(ployload: any) {
    this.projectinfoDetail = ployload;
  }
  @Mutation
  private set_Active(ployload: any) {
    this.active = ployload;
  }

  @Mutation
  private set_Qualitysafe(ployload: any) {
    this.qualitysafe = ployload;
  }

  @Mutation
  private set_sjjdetail(ployload: any) {
    this.sjjdetail = ployload;
  }

  @Mutation
  private set_qzjdetail(ployload: any) {
    this.qzjdetail = ployload;
  }

  @Mutation
  private set_lbfhdetail(ployload: any) {
    this.lbfhdetail = ployload;
  }

  @Mutation
  private set_xlptdetail(ployload: any) {
    this.xlptdetail = ployload;
  }

  @Mutation
  private RESET_PROJECT() {
    this.projectinfo = null;
    this.projectinfoDetail = null;
    this.projectList = {};
  }

  @Mutation
  private setPerms(ployload: any) {
    this.perms = ployload;
  }

  @Action({ rawError: true })
  async actionProjectList(context: any) {
    const res: any = await project.projectlist();
    if (res.code === 0) {
      this.context.commit("SET_PROJECTLIST", res.data);
      if (res.data.length !== 0) {
        let selPro = res.data.filter(
          (item: any) => item.defaultProjectId === item.projectId
        );
        if (selPro.length === 0) {
          selPro = res.data;
        }
        const obj = this.projectinfo;
        if (!obj) {
          this.context.commit("SET_SELECTPROJECT", selPro[0]);
        }
      }
    }
  }

  @Action({ rawError: true })
  async getButtonPermissions(context: any) {
    const res: any = await project.getButtonPermissions(context);
    if (res.code === 0) {
      this.context.commit("setPerms", res.data);
    }
  }
}
