import { createStore } from "vuex";
import persistedState from "vuex-persistedstate";
import { IUserState, User } from "./modules/user";
import { ProjectState, Project } from "./modules/project";

export interface IRootState {
  user: IUserState;
  project: ProjectState;
}

export default createStore<IRootState>({
  plugins: [
    persistedState<IRootState>({
      storage: window.sessionStorage,
      // keep-alive需取消持久化，否则会报错
      // 动态模块需取消持久化，否则会报警告
      reducer(state) {
        const processState: any = { ...state };
        delete processState.tagsView;
        return processState;
      },
    }),
  ],
  modules: {
    user: User,
    project: Project,
  },
});
