import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VTouch from "vue3-touch";
//vant
import Vant from "vant";
import "vant/lib/index.css";

import * as Echarts from "echarts";
//图标库
import "@/icons";
import SvgIcon from "@/components/SvgIcon/index.vue";
//全局样式
import "@/styles/index.scss";
//路由拦截器
import "@/permission";
//移动端适配方案
import "lib-flexible";
import mitter from "@/utils/mitt";

function isiOS() {
  const u = window.navigator.userAgent;
  const iOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return !iOS;
}

function auth(role: any) {
  const perms = store.state.project.perms;
  return perms.indexOf(role) !== -1;
}
const app = createApp(App);

app.config.globalProperties.auth = auth;

app.use(Vant);
app.use(VTouch);
app.component("svg-icon", SvgIcon);
app.use(store);
app.use(router);
app.config.globalProperties.$echarts = Echarts;
app.config.globalProperties.$mitter = mitter;
app.config.globalProperties.$isIOS = isiOS;
app.mount("#app");
export default app;
