import request from "@/utils/request";

// 项目详情
export function mobileprojectdetail(data: any) {
  return request({
    url: "/project/mobile/project/detail",
    method: "get",
    params: data,
  });
}

//地图上的项目总况
export function listIdAndNameByUserId(data: any) {
  return request({
    url: "/project/project/selectProjectInfoByUserId",
    method: "get",
    params: data,
  });
}

// 部门下的子部门和此部门下的人员列表
export function mobilepersondept(data: any) {
  return request({
    url: "/project/mobile/person/dept",
    method: "get",
    params: data,
  });
}

// 工地列表
export function projectlist() {
  return request({
    url: "/project/project/projectIdAndNameList",
    method: "get",
  });
}

// 人员详情
export function mobilepersondetail(data: any) {
  return request({
    url: "project/mobile/person/detail",
    method: "get",
    params: data,
  });
}

export function getButtonPermissions(id: any) {
  return request({
    url: "/center/sys/operation/getButtonPermissions",
    method: "get",
    params: { projectId: id },
  });
}

// 获取看板设备
export function cameraDevicegetBigDataInfoByProjectId(params: any) {
  return request({
    url: "/project/cameraDevice/getBigDataInfoByProjectId",
    method: "get",
    params: params,
  });
}
// 查询设备是否在线
export function hikvisionrunningState(id: any) {
  return request({
    url: "/device-server/hikvision/runningState/" + id,
    method: "get",
  });
}
// 获取播放url
export function getVsURL(deviceId: any, channel: any, protocol: any) {
  return request({
    url: "/device-server/video/getVsURL",
    method: "get",
    params: { deviceId: deviceId, channel: channel, protocol: protocol },
  });
}

// 获取app
export function mobileapplicationgetApps(params: any) {
  return request({
    url: "/center/mobile/application/getApps",
    method: "get",
    params: params,
  });
}
// 获取app路由
export function sysmenugetAppMenus(params: any) {
  return request({
    url: "/center/sys/menu/getAppMenus",
    method: "get",
    params: params,
  });
}

// 当日访客统计
export function kanbanprojectvisitorCountDay(params: any) {
  return request({
    url: "/project/kanban/project/visitorCountDay",
    method: "get",
    params: params,
  });
}
// 当日出勤统计
export function kanbanprojectcheckinCountDay(params: any) {
  return request({
    url: "/project/kanban/project/checkinCountDay",
    method: "get",
    params: params,
  });
}
// 查询今日车辆
export function kanbanprojecttodayCarTotal(params: any) {
  return request({
    url: "/project/kanban/project/todayCarTotal",
    method: "get",
    params: params,
  });
}
// 当日在场人数
export function kanbanprojectcountOnSite(params: any) {
  return request({
    url: "/project/kanban/project/countOnSite",
    method: "get",
    params: params,
  });
}
// 劳务统计
export function kanbanprojectstationCount(params: any) {
  return request({
    url: "/project/kanban/project/stationCount",
    method: "get",
    params: params,
  });
}
// 进出统计（工人-访客）
export function kanbanprojectaccessTotal(params: any) {
  return request({
    url: "/project/kanban/project/accessTotal",
    method: "get",
    params: params,
  });
}
// 人员进出
export function kanbanprojectaccessRecord(params: any) {
  return request({
    url: "/project/kanban/project/accessRecord",
    method: "get",
    params: params,
  });
}

// 进出统计
export function kanbanprojectaccessCount(params: any) {
  return request({
    url: "/project/kanban/project/accessCount",
    method: "get",
    params: params,
  });
}

// 当日按照参建单位类别的考勤统计
export function kanbanprojectcheckinByDeptType(params: any) {
  return request({
    url: "/project/kanban/project/checkinByDeptType",
    method: "get",
    params: params,
  });
}

// 访客统计（分月统计）
export function kanbanprojectvisitorCount(params: any) {
  return request({
    url: "/project/kanban/project/visitorCount",
    method: "get",
    params: params,
  });
}

export function saveDefaultProjectId(id: any) {
  return request({
    url: "/project/project/saveDefaultProjectId/" + id,
    method: "get",
  });
}

// 省平台人员照片比对
export function photoCompare(data: any) {
  return request({
    url: "/project/qunYao/photoCompare",
    method: "post",
    timeout: 30 * 1000,
    data: data,
  });
}
//审平台人员同步
export function syncShengPingTai(projectId: any) {
  return request({
    url: "/project/qunYao/syncWorkers",
    method: "get",
    timeout: 30 * 1000,
    params: {
      projectId: projectId,
    },
  });
}

// 单个人员在所有设备上的同步记录new
export function listUserFaceSyncItem(params: any) {
  return request({
    url: "/face/syncFaceItem/listUserSyncItem",
    method: "get",
    params: params,
  });
}

// 单人多个设备
export function syncOneUserToAllDevice(params: any) {
  return request({
    url: "/face/syncUserFace/syncOneUserToAllDevice",
    method: "get",
    params: params,
  });
}

// 单人单设备
export function syncOneUserToOneDevice(params: any) {
  return request({
    url: "/face/syncUserFace/syncOneUserToOneDevice",
    method: "get",
    params: params,
  });
}
