
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
import { isExternal } from "@/utils/validate";
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop({
    required: true,
  })
  private iconClass!: string;

  @Prop()
  private className!: string;

  get isExternal(): boolean {
    return isExternal(this.iconClass);
  }

  get iconName(): string {
    return `#icon-${this.iconClass}`;
  }

  get svgClass(): string {
    if (this.className) {
      return "svg-icon " + this.className;
    } else {
      return "svg-icon";
    }
  }

  get styleExternalIcon(): any {
    return {
      mask: `url(${this.iconClass}) no-repeat 50% 50%`,
      "-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`,
    };
  }
}
